import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { FaExpeditedssl } from "react-icons/fa";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { format, parseISO } from "date-fns";
import { AllPaidNotice } from "..";
import { FormField } from "../CheckoutForm/FormField";
import { getCep } from "../../hooks/useCep";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

const BRASIL = "BR";
const CARD_BASE_URL = "https://www.asaas.com/assets/creditcard/";
const CARD_FLAGS = [
  {
    image: "visa.png",
    title: "Visa",
  },
  {
    image: "mastercard.png",
    title: "MasterCard",
  },
  {
    image: "elo.png",
    title: "Elo",
  },
  {
    image: "hipercard.png",
    title: "Hipercard",
  },
  {
    image: "discover.png",
    title: "Discover",
  },
  {
    image: "amex.png",
    title: "Amex",
  },
];

export const CreditCardFormFields = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldError,
  errors,
  touched,
  payment,
}) => {
  const [address, setAddress] = React.useState("");
  const { t, i18n } = useTranslation();
  const { validateForm } = useFormikContext();

  useEffect(() => {
    if (validateForm) {
      validateForm();
    }
  }, [i18n.language, validateForm]);

  const { payment: data } = payment;
  const installment = React.useMemo(
    () =>
      data.installments_from_gateway.find(i =>
        ["pending", "overdue"].includes(i.status),
      ),
    [data],
  );

  const allPaid = React.useMemo(
    () =>
      data.installments_from_gateway.filter(i => ["paid"].includes(i.status))
        .length === data.installments_from_gateway.length,
    [data],
  );

  const handleCepBlur = React.useCallback(
    async e => {
      const value = e.target.value;

      if (value.length >= 8) {
        const { error, address: cepAddress } = await getCep(e.target.value);

        if (error) {
          setFieldError(
            "address_zipcode",
            t("cep-nao-encontrado-nos-correios"),
          );
          setAddress("");
        } else {
          setAddress(cepAddress);
        }
      } else {
        setFieldValue("address_zipcode", e.target.value);
      }
    },
    [setFieldValue, t, setFieldError],
  );

  if (allPaid) {
    return <AllPaidNotice />;
  }

  const isBrazilian = values?.address_country.toUpperCase() === BRASIL;

  const alertErrors = errors.global_errors;
  const dueDate = format(parseISO(installment?.due_date), "dd/MM/yyyy");

  return (
    <div>
      <Card className="mt-3">
        <Card.Body>
          <Card.Title as="h5">
            {t("pagar-x-vencimento-x", {
              description: installment.description,
              dueDate,
            })}
          </Card.Title>
          <Form onSubmit={handleSubmit} id="form-checkout-credit-card">
            <Container>
              <Row>
                <Col md={4} className="mt-4">
                  <FormField
                    hideLabel={false}
                    type="tel"
                    label={t("numero-do-cartao")}
                    name="credit_card_number"
                    placeholder={t("digite-os-numeros")}
                    error={errors.credit_card_number}
                    touched={touched.credit_card_number}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.credit_card_number}
                  />

                  <FormField
                    hideLabel={false}
                    type="text"
                    label={t("nome-do-titular-do-cartao")}
                    name="credit_card_holder_name"
                    placeholder={t("digite-como-esta-escrito-no-cartao")}
                    error={errors.credit_card_holder_name}
                    touched={touched.credit_card_holder_name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.credit_card_holder_name}
                  />

                  <p>
                    <strong>{t("validade")}:</strong>
                  </p>

                  <Container className="p-0">
                    <Row>
                      <Col className="d-flex flex-row align-items-center">
                        <FormField
                          hideLabel={false}
                          type="tel"
                          label={t("mes")}
                          name="credit_card_expiry_month"
                          error={errors.credit_card_expiry_month}
                          touched={touched.credit_card_expiry_month}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.credit_card_expiry_month}
                          fieldAttributes={{ maxLength: 2, minLength: 2 }}
                        />

                        <FormField
                          hideLabel={false}
                          type="tel"
                          label={t("ano")}
                          name="credit_card_expiry_year"
                          error={errors.credit_card_expiry_year}
                          touched={touched.credit_card_expiry_year}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.credit_card_expiry_year}
                          className="ms-2 mb-3"
                          fieldAttributes={{ maxLength: 2, minLength: 2 }}
                        />
                      </Col>
                      <Col>
                        <FormField
                          hideLabel={false}
                          type="tel"
                          label={t("codigo-de-seguranca")}
                          name="credit_card_ccv"
                          error={errors.credit_card_ccv}
                          touched={touched.credit_card_ccv}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.credit_card_ccv}
                        />
                      </Col>
                    </Row>
                  </Container>

                  <hr />

                  {isBrazilian && (
                    <div>
                      <p>
                        <strong>{t("endereco-do-cartao")}:</strong>
                      </p>

                      <Container className="p-0">
                        <Row>
                          <Col className="d-flex flex-row align-items-center">
                            <FormField
                              hideLabel={false}
                              type="tel"
                              label="CEP"
                              name="address_zipcode"
                              placeholder="00000-000"
                              error={false}
                              touched={touched.address_zipcode}
                              handleBlur={handleCepBlur}
                              handleChange={handleChange}
                              value={values.address_zipcode}
                            />

                            <FormField
                              className="mb-3 ms-2"
                              hideLabel={false}
                              label={t("numero")}
                              name="address_number"
                              placeholder=""
                              error={errors.address_number}
                              touched={touched.address_number}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              value={values.address_number}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {address}
                            {errors.address_zipcode && (
                              <p className="text-danger mb-0 text-left">
                                {errors.address_zipcode}
                              </p>
                            )}
                          </Col>
                        </Row>
                      </Container>

                      <hr />

                      {isBrazilian && (
                        <FormField
                          hideLabel={false}
                          type="text"
                          label={t("cep-ou-cnpj-do-titular-do-cartao")}
                          name="holder_doc_number"
                          placeholder={t("digite-apenas-numeros")}
                          error={errors.holder_doc_number}
                          touched={touched.holder_doc_number}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.holder_doc_number}
                          bottomChildren={
                            <div>
                              <Form.Text className="text-muted">
                                <FaExpeditedssl />{" "}
                                {t("dados-ficarao-em-sigilo")}
                              </Form.Text>
                            </div>
                          }
                        />
                      )}
                    </div>
                  )}

                  <FormField
                    hideLabel={false}
                    label={t("nome-do-comprador")}
                    name="holder_name"
                    error={errors.holder_name}
                    touched={touched.holder_name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.holder_name}
                  />

                  <FormField
                    hideLabel={false}
                    label={t("telefone-celular")}
                    name="holder_phone_number"
                    placeholder={t("digite-apenas-numeros")}
                    error={errors.holder_phone_number}
                    touched={touched.holder_phone_number}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.holder_phone_number}
                  />

                  <Container className="p-0">
                    <div className="d-grid gap-2">
                      <p className="fs-3 text-success mt-4 d-block d-sm-none">
                        <strong>
                          {t("valor")}: R$ {formatCurrency(installment?.total)}
                        </strong>
                      </p>

                      {alertErrors && (
                        <Alert variant="danger" className="mt-3">
                          {alertErrors}
                        </Alert>
                      )}
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        size="lg"
                        variant="success"
                        className="mt-2 mb-2"
                      >
                        {isSubmitting && (
                          <div>
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                {t("processando")}...
                              </span>
                            </Spinner>
                          </div>
                        )}

                        {!isSubmitting && (
                          <div>
                            <FaExpeditedssl /> {t("efetuar-pagamento")}
                          </div>
                        )}
                      </Button>
                    </div>
                    <hr />

                    <em>
                      <Trans
                        i18nKey="pagamento-na-fatura-como-asaas"
                        components={{ strong: <strong /> }}
                      />
                    </em>
                  </Container>
                </Col>
                <Col className="d-flex align-items-center flex-column justify-content-center">
                  <p className="fs-3 text-success mt-4 d-none d-sm-block">
                    <strong>
                      {t("valor")}: R$ {formatCurrency(installment?.total)}
                    </strong>
                  </p>

                  <h6 className="mt-3 fs-5">{t("aceitamos-cartoes")}:</h6>

                  <div>
                    {CARD_FLAGS.map(f => (
                      <img
                        src={`${CARD_BASE_URL + f.image}`}
                        title={f.title}
                        width="40"
                        height="30"
                        className="m-1"
                        key={f.image}
                        alt={f.title}
                      />
                    ))}
                  </div>

                  <img
                    className="mt-3"
                    src="https://s3-sa-east-1.amazonaws.com/asaas-static/images/invoice/site-seguro.png"
                    alt={t("imagem-informando-site-seguro")}
                  />
                </Col>
              </Row>
            </Container>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

CreditCardFormFields.propTypes = {
  payment: PropTypes.object,
};

export default CreditCardFormFields;
