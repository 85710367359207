import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { ConfirmModal } from "..";
import {
  BILLING_TYPE_OPTIONS,
  translateBillingType,
} from "../utils/billing_types";
import { FaExpeditedssl } from "react-icons/fa";
import { CheckoutPropType } from "../utils/CheckoutPropType";
import { CheckoutFormOptions } from "./CheckoutFormOptions";
import { FormField } from "./FormField";
import CheckoutUtils from "../utils/CheckoutUtils";
import { CheckoutFormCustomFields } from "./CheckoutFormCustomFields";
import { useUTMParameters } from "use-utm-parameters";
import { CREDIT_CARD_BILLING_TYPE } from "../utils/CheckoutBillingTypeUtils";
import { useAffiliateCode } from "../../hooks/useAffiliateCode";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

const BRASIL = "BR";

export const CheckoutFormFields = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  validateField,
  setFieldTouched,
  errors,
  touched,
  isValid,
  countries,
  setValues,
  checkout = {},
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { t, i18n } = useTranslation();

  const { validateForm } = useFormikContext();

  useEffect(() => {
    validateForm();
  }, [i18n.language, validateForm]);

  const [submitButtonText, setSubmitButtonText] = React.useState(
    t("pagar-e-receber-meu-produto"),
  );

  const utmParams = useUTMParameters(global.location.search);
  const affiliateCode = useAffiliateCode();
  const billingTypesAvailable = checkout?.billing_types_options;
  const isBrazilian = values?.country.toUpperCase() === BRASIL;

  const canChooseCountry = billingTypesAvailable?.includes(
    CREDIT_CARD_BILLING_TYPE,
  )
    ? true
    : false;

  const currentBillingType = checkout?.billing_types[values?.billing_type];

  // For now, if gringos, don't allow to choose installments.
  const installments = isBrazilian
    ? currentBillingType.installments
    : [currentBillingType.installments[0]];

  const customFields = checkout?.custom_fields_available;

  const onConfirmModal = () => {
    setModalShow(false);
    handleSubmit();
  };

  const onCloseModal = () => setModalShow(false);

  const onSubmit = e => {
    e.preventDefault();
    setFieldValue("utm_params", utmParams);

    if (affiliateCode) {
      setFieldValue("affiliate_code", affiliateCode);
    }

    if (isValid && touched.name) {
      setModalShow(true);
    } else {
      handleSubmit();
    }
  };

  const alertErrors = errors.global_error || errors.checkout_id;

  const countryOptions = React.useMemo(
    () =>
      countries?.map(i => (
        <option key={i.Code} value={i.Code}>
          {i.Name}
        </option>
      )),
    [countries],
  );

  const handleInstallmentChange = React.useCallback(
    e => {
      if (!isBrazilian) {
        setValues({
          ...values,
          installment_count: 1,
          billing_type: CREDIT_CARD_BILLING_TYPE,
        });
      }

      handleChange(e);
    },
    [isBrazilian, handleChange, setValues, values],
  );

  const handleBillingTypeChange = React.useCallback(
    selectedBillingType => {
      setValues({
        ...values,
        billing_type: selectedBillingType,
        installment_count: 1,
      });
    },
    [setValues, values],
  );

  const handleCountryChange = React.useCallback(
    e => {
      setValues({
        ...values,
        installment_count: 1,
        billing_type: CREDIT_CARD_BILLING_TYPE,
      });

      setTimeout(() => {
        validateField("installment_count");
        setFieldTouched("installment_count");
      }, 300);

      handleChange(e);
    },
    [handleChange, setValues, validateField, setFieldTouched, values],
  );

  useEffect(() => {
    if (values.billing_type === BILLING_TYPE_OPTIONS.pix) {
      setSubmitButtonText(t("gerar-pix-e-pagar"));
    }

    if (values.billing_type === BILLING_TYPE_OPTIONS.boleto) {
      setSubmitButtonText(t("gerar-boleto-e-pagar"));
    }
    if (values.billing_type === BILLING_TYPE_OPTIONS.creditCard) {
      setSubmitButtonText(t("pagar-com-cartao-de-credito"));
    }
  }, [values.billing_type, t]);

  return (
    <div>
      <ConfirmModal
        centered={true}
        fullscreen="md-down"
        onConfirm={onConfirmModal}
        onClose={onCloseModal}
        show={modalShow}
        text={
          <div
            className="d-flex align-items-center fs-large-text-sm"
            style={{ minHeight: "100%" }}
          >
            <div>
              {t("voce-escolheu")}{" "}
              <strong className="text-warning">
                {t("x-opcoes-escolhidas_other", {
                  billingType: translateBillingType(values.billing_type),
                  installmentCount: values.installment_count,
                })}
                {t(
                  values.installment_count > 1
                    ? "x-opcoes-escolhidas_many"
                    : "x-opcoes-escolhidas_one",
                )}
              </strong>
              . {t("deseja-continuar")}
            </div>
          </div>
        }
      />

      <div>
        <Form onSubmit={onSubmit} id="form-checkout-fields">
          <input
            name="billing_type"
            type="hidden"
            defaultValue={values.billing_type}
          />

          <h5 className="visually-hidden visually-hidden-focusable">
            {t("seus-dados")}
          </h5>

          <Container>
            <Row className="justify-content-md-center">
              <Col md={9} lg={7}>
                <Card body p={2}>
                  {canChooseCountry && (
                    <FloatingLabel
                      controlId="country"
                      label={t("nacionalidade")}
                    >
                      <Form.Select
                        value={values.country}
                        onBlur={handleBlur}
                        onChange={handleCountryChange}
                        aria-label={t("nacionalidade")}
                        className="mb-3"
                      >
                        {countryOptions}
                      </Form.Select>
                    </FloatingLabel>
                  )}

                  {!isBrazilian && (
                    <p className="text-primary text-left">
                      {t("pagamentos-com-moedas-estrangeiras-msg")}
                    </p>
                  )}

                  <FormField
                    type="text"
                    label={
                      <>
                        {t("nome-completo")}{" "}
                        <div
                          className="fw-light m-0 p-0 text-body-secondary"
                          style={{ fontSize: 14 }}
                        >
                          {t("preencher-como-consta-no-id")}
                        </div>
                      </>
                    }
                    name="name"
                    placeholder={t("digitar-nome-completo")}
                    error={errors.name}
                    touched={touched.name}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.name}
                  />

                  <div className="container-fluid px-0">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <FormField
                          type="email"
                          label={t("digitar-email")}
                          placeholder={t("digitar-email")}
                          name="email"
                          error={errors.email}
                          touched={touched.email}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.email}
                          fieldAttributes={{
                            readOnly: !!values?.checkout_unlock,
                          }}
                        />
                      </div>
                      <div className="col-12 col-lg-6">
                        <FormField
                          type="email"
                          label={t("confirmar-email")}
                          placeholder={t("deve-ser-igual-ao-campo-anterior")}
                          name="email_confirmation"
                          error={errors.email_confirmation}
                          touched={touched.email_confirmation}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          value={values.email_confirmation}
                          fieldAttributes={{
                            readOnly: !!values?.checkout_unlock,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {isBrazilian && (
                    <FormField
                      type="text"
                      label={
                        <>
                          {t("cpf-ou-cnpj")}{" "}
                          <span
                            className="fw-light m-0 p-0 text-body-secondary"
                            style={{ fontSize: 14 }}
                          >
                            {t("digiar-documento-de-nome-completo")}
                          </span>
                        </>
                      }
                      name="doc_number"
                      placeholder={t("somente-numeros")}
                      error={errors.doc_number}
                      touched={touched.doc_number}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.doc_number}
                    />
                  )}

                  <FormField
                    label={
                      <>
                        {t("telefone-celular")}{" "}
                        <span
                          className="fw-light m-0 p-0 text-body-secondary"
                          style={{ fontSize: 14 }}
                        >
                          {t("preferencia-com-whatsapp")}
                        </span>
                      </>
                    }
                    placeholder={t("somente-numeros")}
                    name="phone_number"
                    error={errors.phone_number}
                    touched={touched.phone_number}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.phone_number}
                  />

                  {values?.checkout_unlock && (
                    <Alert variant="warning">
                      {t("email-nao-pode-ser-alterado-msg")}{" "}
                      <Link
                        to={`${CheckoutUtils.buildURL(
                          checkout?.id,
                          checkout?.slug,
                        )}/clear_unlock`}
                      >
                        {t("clique-aqui")}
                      </Link>
                      .
                    </Alert>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>

          {values.billing_type && billingTypesAvailable.length > 1 && (
            <div>
              {isBrazilian && (
                <CheckoutFormOptions
                  onSelect={handleBillingTypeChange}
                  options={billingTypesAvailable}
                  currentBillingType={currentBillingType}
                />
              )}

              <Container>
                <Row className="justify-content-md-center">
                  <Col md={9} lg={7}>
                    {errors.billing_type && touched.billing_type ? (
                      <p className="text-danger">{errors.billing_type}</p>
                    ) : null}
                  </Col>
                </Row>
              </Container>
            </div>
          )}

          {(billingTypesAvailable.length > 1 || installments?.length > 1) && (
            <div>
              <h2 className="my-3 fs-5">{t("escolha-sua-parcela")}:</h2>
              <Container>
                <Row className="justify-content-center">
                  <Col lg={7}>
                    {!isBrazilian && (
                      <Alert variant="info">
                        {t("informacoes-de-pagamentos-nao-residentes")}
                      </Alert>
                    )}

                    <fieldset>
                      <Card body>
                        <Row>
                          {installments.map(({ description, installment }) => (
                            <Col key={description} sm={6}>
                              <Form.Check
                                type="radio"
                                id={`installment-count-${installment}`}
                                className="text-left"
                              >
                                <Form.Check.Input
                                  type="radio"
                                  name="installment_count"
                                  onChange={handleInstallmentChange}
                                  value={installment}
                                  onBlur={handleBlur}
                                  checked={
                                    values.installment_count.toString() ===
                                    installment.toString()
                                  }
                                />
                                <Form.Check.Label className="px-2">
                                  {description.replace(".", ",")}
                                </Form.Check.Label>
                              </Form.Check>
                            </Col>
                          ))}
                        </Row>
                        {installments?.length > 1 && (
                          <p className="text-center fs-6 fw-light mb-0 mt-2">
                            <small>
                              {t("informacoes-de-acrescimo-de-x", {
                                interestRate: parseFloat(
                                  currentBillingType?.interest_rate,
                                ).toFixed(2),
                              })}
                            </small>
                          </p>
                        )}
                      </Card>
                    </fieldset>
                  </Col>
                </Row>
              </Container>
            </div>
          )}

          {errors.installment_count && (
            <Container className="mt-2">
              <Row>
                <Col>
                  <p className="text-danger mb-0 text-center mb-0">
                    {errors.installment_count}
                  </p>
                </Col>
              </Row>
            </Container>
          )}

          {!!customFields?.length && (
            <CheckoutFormCustomFields
              errors={errors}
              values={values}
              customFields={customFields}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          )}

          <Container>
            <Row className="justify-content-md-center">
              <Col md={9} lg={7}>
                {alertErrors && <Alert variant="danger">{alertErrors}</Alert>}

                <div className="d-grid gap-2">
                  <Button
                    disabled={isSubmitting}
                    variant="success"
                    type="submit"
                    className="mt-3 p-3"
                  >
                    {isSubmitting && (
                      <div>
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">
                            {t("processando")}...
                          </span>
                        </Spinner>
                      </div>
                    )}

                    {!isSubmitting && (
                      <div>
                        <FaExpeditedssl /> {submitButtonText}
                      </div>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    </div>
  );
};

CheckoutFormFields.propTypes = {
  checkout: CheckoutPropType,
};
