import PropTypes from "prop-types";
import styled from "styled-components";
import { FaWhatsapp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Anchor = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
`;

const Icon = styled(FaWhatsapp)`
  margin-top: 10px;
`;

export const Whatsapp = ({ url }) => {
  const { t } = useTranslation();

  return (
    <Anchor
      href={url}
      target="_blank"
      title={t("clique-para-entrar-em-contato-pelo-whatsapp")}
    >
      <Icon />
    </Anchor>
  );
};

Whatsapp.propTypes = {
  url: PropTypes.string,
};

export default Whatsapp;
