import deTranslations from "./translations/de-DE.json";
import enTranslations from "./translations/en.json";
import esTranslations from "./translations/es.json";
import frTranslations from "./translations/fr-FR.json";
import ptTranslations from "./translations/pt-BR.json";

export const visitorAPILanguagesMapper = {
  eng: "en",
  por: "pt",
  ger: "de",
  spa: "es",
  deu: "de",
  fra: "fr",
};

export const i18nConfig = {
  // the translations
  // (tip move them in a JSON file and import them,
  // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
  resources: {
    en: {
      translation: enTranslations,
    },
    de: {
      translation: deTranslations,
    },
    fr: {
      translation: frTranslations,
    },
    pt: {
      translation: ptTranslations,
    },
    es: {
      translation: esTranslations,
    },
  },
  lng: "pt", // if you're using a language detector, do not define the lng option

  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
};
