import React from "react";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import { TopBar } from "../components";
import { PaymentRecoverForm } from "../components/PaymentRecoverForm/PaymentRecoverForm";
import { GiMoneyStack } from "react-icons/gi";
import { Trans, useTranslation } from "react-i18next";

function PagePaymentRecover() {
  const history = useHistory();
  const { t } = useTranslation();

  function setData(url) {
    history.push(encodeURI(url));
  }

  return (
    <div>
      <TopBar />

      <Container className="text-center">
        <h1>
          {t("segunda-via-de-pagamentos")} <GiMoneyStack />
        </h1>
        <p>
          <Trans
            i18nKey="digite-o-numero-do-documento"
            components={{ strong: <strong /> }}
          />
        </p>

        <PaymentRecoverForm onResults={setData} />
      </Container>
    </div>
  );
}

export default PagePaymentRecover;
