import styled from "styled-components";

export const LanguageSelectWrapper = styled.div`
  > div > div:first-of-type {
    background: transparent;
    border-color: #fff;
    box-shadow: none;
    padding-right: 0;

    > div {
      color: #fff;
      display: flex;
      padding-right: 0;

      > div:last-child {
        width: 28px;
        padding: 0;
      }

      > span:first-child {
        display: none;
      }
    }
  }

  svg {
    fill: #fff;
  }

  div > div {
    cursor: pointer;
  }

  color: #000;
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
  font-size: 13px;
`;

export const CustomSingleValueWrapper = styled.p`
  display: flex;
  margin: 0;
  align-items: flex-start;
  justify-content: center;
  gap: 0.3rem;
`;
