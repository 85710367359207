import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { FormField } from "../CheckoutForm/FormField";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

export const PaymentRecoverFormFields = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  errors,
  touched,
}) => {
  const { i18n } = useTranslation();
  const { validateForm } = useFormikContext();

  useEffect(() => {
    if (validateForm) {
      validateForm();
    }
  }, [i18n.language, validateForm]);
  const alertErrors = errors.global_error || errors.checkout_id;

  return (
    <Form onSubmit={handleSubmit} id="form-checkout-recover">
      <Card>
        <Card.Body>
          <Container className="text-left">
            <Row className="justify-content-md-center">
              <Col md={9} lg={7}>
                <FormField
                  type="email"
                  label="E-mail de compra"
                  name="email"
                  error={errors.email}
                  touched={touched.email}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  value={values.email}
                  hideLabel={false}
                />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={9} lg={7}>
                <FormField
                  type="text"
                  label="CPF ou CNPJ da compra"
                  name="doc_number"
                  placeholder="Digite o CPF ou CNPJ do titular da compra"
                  error={errors.doc_number}
                  touched={touched.doc_number}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  value={values.doc_number}
                  hideLabel={false}
                />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={9} lg={7}>
            {alertErrors && <Alert variant="danger">{alertErrors}</Alert>}

            <div className="d-grid gap-2">
              <Button
                disabled={isSubmitting}
                variant="success"
                type="submit"
                className="mt-3 p-3"
              >
                {isSubmitting && (
                  <div>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Processando...</span>
                    </Spinner>
                  </div>
                )}

                {!isSubmitting && <div>Buscar pagamentos</div>}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

PaymentRecoverFormFields.propTypes = {};
