import "normalize.css";
import React from "react";

import { createGlobalStyle } from "styled-components";
import PageDetails from "./Pages/PageDetails";
import PageError from "./Pages/PageError";
import PagePayment from "./Pages/PagePayment";
import PagePaymentSuccess from "./Pages/PagePaymentSuccess";
import PagePaymentRecover from "./Pages/PagePaymentRecover";
import PageLocked from "./Pages/PageLocked";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Themes from "./config/theme";
import { ThemeContext } from "./components/utils/themeContext";
import PagePayments from "./Pages/PagePayments";
import { VisitorDataUtil } from "./components/utils/visitor_data";
import PageClearUnlock from "./Pages/PageClearUnlock";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18nConfig } from "./config/i18n/";

i18n.use(initReactI18next).init(i18nConfig);

const GlobalStyle = createGlobalStyle`
  :root {
    --brand: ${props => props.theme.colors.primary};
  }
`;

function App() {
  const [chosenTheme, setChosenTheme] = React.useState(null);

  React.useEffect(() => {
    const theme =
      Themes[
        process.env.REACT_APP_APP_THEME ||
          process.env.REACT_APP_DEFAULT_APP_THEME
      ];
    setChosenTheme(theme);
  }, []);

  React.useEffect(() => {
    async function setVisitorData() {
      await VisitorDataUtil.set();
    }

    setVisitorData();
  }, []);

  if (!chosenTheme) {
    return null;
  }

  return (
    <main>
      <GlobalStyle theme={chosenTheme} />
      <ThemeContext.Provider value={chosenTheme}>
        <Router>
          <Switch>
            <Route path="/meus-pagamentos">
              <PagePayments />
            </Route>
            <Route path="/segunda-via">
              <PagePaymentRecover />
            </Route>
            <Route path="/payments/:id/success">
              <PagePaymentSuccess />
            </Route>
            <Route path="/payments/:id">
              <PagePayment />
            </Route>

            <Route path="/:id/:slug/locked">
              <PageLocked />
            </Route>

            <Route path="/:id/:slug/clear_unlock">
              <PageClearUnlock />
            </Route>

            <Route path="/:id/:slug">
              <PageDetails />
            </Route>
            <Route path="*">
              <PageError />
            </Route>
          </Switch>
        </Router>
      </ThemeContext.Provider>
    </main>
  );
}

export default App;
