import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { FaExpeditedssl } from "react-icons/fa";
import { CheckoutPropType } from "../utils/CheckoutPropType";
import { FormField } from "../CheckoutForm/FormField";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

export const CheckoutLockedApoloServiceFormFields = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  errors,
  touched,
  instruction,
}) => {
  const { t, i18n } = useTranslation();
  const { validateForm } = useFormikContext();
  const alertErrors = errors.global_error || errors.checkout_id;

  useEffect(() => {
    validateForm();
  }, [i18n.language, validateForm]);

  return (
    <div>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={9}>
            <Alert variant="info">
              <Alert.Heading>{t("oque-ouve-aqui")}</Alert.Heading>
              <p>{t("compra-precisa-de-confirmacao-previa")}</p>
            </Alert>
          </Col>
        </Row>
      </Container>
      <Form onSubmit={handleSubmit} id="form-checkout-locked">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={9} lg={7}>
              <Card body p={2}>
                <FormField
                  type="email"
                  placeholder="exemplo@seuprovedor.com.br"
                  name="user_email"
                  error={errors.user_email}
                  touched={touched.user_email}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  value={values.user_email}
                  label={instruction}
                  hideLabel={false}
                />
              </Card>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-md-center">
            <Col md={9} lg={7}>
              {alertErrors && <Alert variant="danger">{alertErrors}</Alert>}

              <div className="d-grid gap-2">
                <Button
                  disabled={isSubmitting}
                  variant="success"
                  type="submit"
                  className="mt-3 p-3"
                >
                  {isSubmitting && (
                    <div>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">
                          {t("processando")}...
                        </span>
                      </Spinner>
                    </div>
                  )}

                  {!isSubmitting && (
                    <div>
                      <FaExpeditedssl /> {t("validar-certificado-e-continuar")}
                    </div>
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

CheckoutLockedApoloServiceFormFields.propTypes = {
  checkout: CheckoutPropType,
};
