import { withFormik } from "formik";
import { CheckoutLockedApoloServiceFormFields } from "./CheckoutLockedApoloServiceFormFields";
import { api } from "../../api";
import { CheckoutLockedUtil } from "./utils/CheckoutLockedUtil";
import { t } from "i18next";

const defaultErrorMessage = t("request-nao-pode-ser-feito");

export const CheckoutLockedApoloServiceForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ checkout }) => ({
    user_email: "",
    checkout_id: checkout?.id,
    global_error: "",
  }),

  // Custom sync validation
  validate: values => {
    const errors = {};

    if (!values.user_email) {
      errors.user_email = t("email-error");
    }

    return errors;
  },

  handleSubmit: async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);

    try {
      const request = await api.post(`/checkout/${values.checkout_id}/unlock`, {
        checkout_unlock: {
          data: {
            user_email: values.user_email,
          },
        },
      });

      if (request.data?.token) {
        setSubmitting(true);
        global?.Sentry?.captureMessage("Checkout Unlock Success", {
          data: request.data,
        });

        CheckoutLockedUtil.set(request.data?.token, values.user_email);

        global.location.href = `/${values.checkout_id}/compra-autorizada`;
      } else {
        setSubmitting(false);

        setFieldError("global_error", t("checkout-nao-pode-ser-desbloqueado"));
      }
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        // All fields are returned with their own key and error, but for us, we only care about true/false here for this service.
        if (error.response.status === 422) {
          setFieldError("user_email", t("user-email-error"));

          if (error.response.error) {
            setFieldError("global_error", error.response.error);
          }
        } else {
          setFieldError("global_error", defaultErrorMessage);
        }
      } else {
        setFieldError("global_error", defaultErrorMessage);
      }
    }
  },

  displayName: "CheckoutLockedApoloServiceForm",
})(CheckoutLockedApoloServiceFormFields);
