import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import { format, parseISO } from "date-fns";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { BiCheckShield } from "react-icons/bi";
import { Redirect, useParams } from "react-router";

import {
  BoletoForm,
  CreditCardForm,
  Loading,
  PixForm,
  TopBar,
  Whatsapp,
} from "../components";
import { usePayment } from "../hooks/usePayment";
import {
  BILLING_TYPES,
  BILLING_TYPE_OPTIONS,
} from "../components/utils/billing_types";
import {
  colorSuccess,
  parseStatus,
  parseStatusColor,
  parseStatusIcon,
} from "../components/utils/payment_statuses";
import { useQuery } from "../hooks/useQuery";
import { useTranslation } from "react-i18next";

const PaymentFormOptions = (billingType = "") => {
  switch (billingType) {
    case `${BILLING_TYPE_OPTIONS.creditCard}`:
      return CreditCardForm;

    case `${BILLING_TYPE_OPTIONS.boleto}`:
      return BoletoForm;

    case `${BILLING_TYPE_OPTIONS.pix}`:
      return PixForm;

    default:
      return CreditCardForm;
  }
};

export const PagePayment = () => {
  const { id } = useParams();
  const query = useQuery();
  const referenceId = query.get("reference_id");
  const { getPayment, loading, payment, error } = usePayment(id);
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getPayment(id), [id]);

  if (loading || !payment) {
    return <Loading />;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  const { payment: paymentData } = payment;
  const { checkout } = paymentData;
  const PaymentForm = PaymentFormOptions(paymentData.billing_type);
  const formatDate = date => format(parseISO(date), "dd/MM/yyyy");

  return (
    <div>
      <Whatsapp url={checkout?.whatsapp_support_line} />
      <TopBar />

      <Container>
        <Card>
          <Card.Body>
            <Card.Title as="h5">{t("detalhes-do-seu-pagamento")}</Card.Title>
            <Card.Text>
              {t("produto")}: <strong>{checkout?.name}</strong>
              <br />
              {t("voce-escolheu")}{" "}
              <strong>{BILLING_TYPES[paymentData.billing_type]}</strong>{" "}
              {t("em-x-vezes", {
                installmentCount: paymentData.installment_count,
              })}
              <br />
              {t("numero-do-pedido")}: #{paymentData.invoice_number}
            </Card.Text>
          </Card.Body>
        </Card>

        <PaymentForm payment={payment} referenceId={referenceId} />

        {paymentData?.installments_from_gateway && (
          <Accordion className="mt-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h5 className="m-0">{t("resumo-de-suas-parcelas")}</h5>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  {paymentData?.installments_from_gateway.map(installment => (
                    <li key={installment.gateway_id}>
                      {parseStatusIcon(installment?.status)({
                        color: parseStatusColor(installment?.status),
                      })}{" "}
                      ({formatDate(installment.due_date)}){" "}
                      {installment.description} - R${" "}
                      {formatCurrency(installment?.total)}
                      <span> - {parseStatus(installment.status)}</span>
                      {installment.status === "paid" && (
                        <a
                          href={`https://www.asaas.com/i/${installment.gateway_id
                            .toString()
                            .replace("pay_", "")}`}
                          target="_blank"
                          rel="noreferrer"
                          title={t("clique-para-emitir-comprovante")}
                          className="ms-2"
                        >
                          ({t("ver-comprovante")})
                        </a>
                      )}
                    </li>
                  ))}
                </ul>

                <p className="text-left ms-3 mb-0">
                  *
                  {t("observacao-de-pagamento", {
                    interestRate: parseFloat(checkout?.interest_rate).toFixed(
                      2,
                    ),
                  })}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </Container>

      <Container>
        <Row>
          <Col className="p-3">{t("pagina-sempre-disponivel")}</Col>
        </Row>
        <Row>
          <Col className="text-success text-center m-3 fs-6">
            <BiCheckShield fontSize={40} color={colorSuccess} />{" "}
            {t("compra-cem-por-cento-segura")}.
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PagePayment;
