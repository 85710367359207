import React, { useState } from "react";
import Select from "react-select";
import { LanguageSelectWrapper, CustomSingleValueWrapper } from "./styled";
import { useTranslation } from "react-i18next";

const languages = [
  { value: "pt", flag: "🇧🇷", label: "🇧🇷 Portuguese | Português" },
  { value: "en", flag: "🇬🇧", label: "🇬🇧 English | Inglês" },
  { value: "es", flag: "🇪🇸", label: "🇪🇸 Spanish | Espanhol" },
  { value: "de", flag: "🇩🇪", label: "🇩🇪 German | Alemão" },
  { value: "fr", flag: "🇫🇷", label: "🇫🇷 French | Francês" },
];

const CustomSingleValue = ({ data, selectProps }) => {
  const { t } = useTranslation();
  const activeLanguage = languages.find(({ label }) => label === data.label);

  return (
    <CustomSingleValueWrapper>
      {activeLanguage?.flag}{" "}
      <span>{t(selectProps.menuIsOpen ? "fechar" : "alterar-pais")}</span>
    </CustomSingleValueWrapper>
  );
};

function LanguageSelect() {
  const { i18n } = useTranslation();
  const initialLanguage = languages.find(lang => i18n.language === lang.value);
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);

  const handleLanguageChange = selectedOption => {
    i18n.changeLanguage(selectedOption.value);
    setSelectedLanguage(selectedOption);

    localStorage.setItem("selectedLanguage", selectedOption.value);
  };

  return (
    <LanguageSelectWrapper>
      <Select
        options={languages}
        value={selectedLanguage}
        onChange={handleLanguageChange}
        placeholder="Select a language"
        isSearchable={false}
        components={{ SingleValue: CustomSingleValue }}
        styles={{
          control: provided => ({
            ...provided,
            width: "184px",
          }),
          menu: provided => ({
            ...provided,
            minWidth: "220px",
            marginLeft: "-4.5rem",
          }),
        }}
      />
    </LanguageSelectWrapper>
  );
}

export default LanguageSelect;
