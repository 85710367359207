import VisitorAPI from "visitorapi";
import { visitorAPILanguagesMapper } from "../../config/i18n";
import i18next from "i18next";

const ONE_DAY_IN_MILLISECONDS = 86400000;

const setSiteLanguage = languages => {
  const lastSelectedLanguage = localStorage.getItem("selectedLanguage");

  if (lastSelectedLanguage) return i18next.changeLanguage(lastSelectedLanguage);

  const foundedLanguage = languages.find(
    language => visitorAPILanguagesMapper[language],
  );

  if (!foundedLanguage) return;

  i18next.changeLanguage(visitorAPILanguagesMapper[foundedLanguage]);
};

const requestAndSaveData = () => {
  VisitorAPI(process.env.REACT_APP_VISITOR_DATA_KEY, data => {
    console.log("## data", data);
    setSiteLanguage(data.languages);

    global.localStorage.setItem("visitorData", JSON.stringify(data));
    global.localStorage.setItem("visitorDataExpiration", new Date().getTime());
  });
};

export const VisitorDataUtil = {
  set: async () => {
    console.log("Initial visitor data request sent");

    const visitorDataExpiration = global.localStorage.getItem(
      "visitorDataExpiration",
    );

    const visitorData = global.localStorage.getItem("visitorData");

    if (!visitorDataExpiration || !visitorData) {
      console.log("## teste");
      return requestAndSaveData();
    }

    const now = new Date().getTime();
    const hasExpired =
      visitorDataExpiration &&
      now - visitorDataExpiration > ONE_DAY_IN_MILLISECONDS;

    if (hasExpired || !visitorData) {
      global.localStorage.removeItem("visitorData");
      global.localStorage.removeItem("visitorDataExpiration");

      requestAndSaveData();
      return;
    }

    const data = JSON.parse(visitorData);
    setSiteLanguage(data.languages);
  },

  get: () => {
    const visitorData = global.localStorage.getItem("visitorData") || null;

    return JSON.parse(visitorData);
  },
};
