import { withFormik } from "formik";
import { PaymentRecoverFormFields } from "./PaymentRecoverFormFields";
import { api } from "../../api";
import { isValidCPF, isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import { withRouter } from "react-router-dom";
import { t } from "i18next";

const defaultErrorMessage = t("request-nao-pode-ser-feito");

export const PaymentRecoverForm = withRouter(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({
      doc_number: "",
      email: "",
      global_error: "",
    }),

    // Custom sync validation
    validate: values => {
      const errors = {};
      const baseErrorDocNumber = t("documento-invalido");

      if (!values.doc_number) {
        errors.doc_number = baseErrorDocNumber;
      } else if (
        !isValidCPF(values.doc_number) &&
        !isValidCNPJ(values.doc_number)
      ) {
        errors.doc_number = baseErrorDocNumber;
      }

      if (!values.email) {
        errors.email = t("email-error");
      }

      return errors;
    },

    handleSubmit: async (
      values,
      { setSubmitting, setFieldError, props: { onResults } },
    ) => {
      setSubmitting(true);

      try {
        const params = `doc_number=${values.doc_number}&email=${values.email}`;
        const request = await api.get(`/payments?${params}`, values);

        if (request?.data?.payments?.length) {
          setSubmitting(true);
          onResults(`/meus-pagamentos?${params}`);
        } else {
          setSubmitting(false);

          setFieldError("global_error", t("pagamentos-ativos-nao-encontrados"));
        }
      } catch (error) {
        setSubmitting(false);
        if (error.response) {
          setFieldError(
            "global_error",
            t("cliente-nao-encontrado-para-email-e-cpf"),
          );
        } else {
          console.log(error);
          setFieldError("global_error", defaultErrorMessage);
        }
      }
    },

    displayName: "PaymentRecoverForm",
  })(PaymentRecoverFormFields),
);
