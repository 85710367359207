import { withFormik } from "formik";
import { CheckoutFormFields } from "./CheckoutFormFields";
import { api } from "../../api";
import { isValidCPF, isValidCNPJ } from "@brazilian-utils/brazilian-utils";
import { VisitorDataUtil } from "../utils/visitor_data";
import { CheckoutLockedUtil } from "../CheckoutLocked/utils/CheckoutLockedUtil";
import { CREDIT_CARD_BILLING_TYPE } from "../utils/CheckoutBillingTypeUtils";
import { t } from "i18next";

const defaultErrorMessage = t("request-nao-pode-ser-feito");
const BRASIL = "BR";

export const CheckoutForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ checkout }) => ({
    name: "",
    utm_params: {},
    doc_number: "",
    affiliate_code: "",
    phone_number: "",
    country: BRASIL,
    payment_custom_field_answers: [],
    global_error: "",
    checkout_id: checkout?.id,
    locked: checkout?.locked,
    billing_type: checkout?.billing_types_options.includes(
      CREDIT_CARD_BILLING_TYPE,
    )
      ? CREDIT_CARD_BILLING_TYPE
      : checkout?.billing_types_options?.[0],
    email: (() => {
      const checkoutLocked = CheckoutLockedUtil.get() ?? {};

      return checkoutLocked?.email ?? "";
    })(),
    email_confirmation: (() => {
      const checkoutLocked = CheckoutLockedUtil.get() ?? {};

      return checkoutLocked?.email ?? "";
    })(),
    installment_count: (() => {
      const hasCreditCard = checkout?.billing_types_options.includes(
        CREDIT_CARD_BILLING_TYPE,
      );

      if (hasCreditCard) {
        return checkout?.billing_types[CREDIT_CARD_BILLING_TYPE]
          ?.installments[0]?.installment;
      }

      return checkout?.billing_types[checkout?.billing_types_options[0]]
        ?.installments[0]?.installment;
    })(),
    checkout_unlock: (() => {
      const checkoutLocked = CheckoutLockedUtil.get();

      if (!checkoutLocked?.token) {
        return null;
      }

      return {
        data: {
          token: checkoutLocked?.token,
        },
      };
    })(),
  }),

  // Custom sync validation
  validate: (values, props) => {
    try {
      const customFieldsAvailable = props?.checkout?.custom_fields_available;
      const errors = {};
      const defaultGringoError = t("default-gringo-error");
      const baseErrorDocNumber = t("documento-invalido");

      if (!values.name) {
        errors.name = t("nome-completo-error");
      }

      if (!values?.installment_count || values?.installment_count <= 0) {
        errors.installment_count = t("quantidades-de-parcelas-error");
      }

      if (values?.country !== BRASIL && values?.installment_count > 1) {
        errors.installment_count = defaultGringoError;
      }

      if (!values.billing_type) {
        errors.billing_type = t("billing-type-error");
      }

      if (values?.country !== BRASIL && values?.billing_type === "BOLETO") {
        errors.billing_type = defaultGringoError;
      }

      // Validate custom fields
      if (customFieldsAvailable?.length) {
        customFieldsAvailable?.forEach((field, i) => {
          if (!field?.required) {
            return;
          }

          const fieldValue = values?.payment_custom_field_answers?.[i]?.answer
            ?.toString()
            ?.trim();

          if (!fieldValue) {
            if (!errors.payment_custom_field_answers?.length) {
              errors.payment_custom_field_answers = [];
            }

            errors.payment_custom_field_answers[i] = t("campo-obrigatorio");
          }
        });
      }

      if (values.country === BRASIL) {
        if (!values.doc_number) {
          errors.doc_number = baseErrorDocNumber;
        } else if (
          !isValidCPF(values.doc_number) &&
          !isValidCNPJ(values.doc_number)
        ) {
          errors.doc_number = baseErrorDocNumber;
        }
      }

      if (!values.phone_number) {
        if (values.country === BRASIL) {
          errors.phone_number = t("preencher-celular-com-ddd");
        } else {
          errors.phone_number = t("preencher-celular-com-codigo-do-pais");
        }
      }

      if (!values.email) {
        errors.email = t("email-error");
      }

      if (
        !values.email_confirmation ||
        values.email !== values.email_confirmation
      ) {
        errors.email_confirmation = t("confirmacao-de-email-error");
      }

      return errors;
    } catch (error) {
      console.log("error", error);
    }
  },

  handleSubmit: async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);

    // Audit Data for Payment Gateways using VisitorAPI
    const visitor_data = VisitorDataUtil.get();

    // UTM Parameters - Only send if they exist
    const utm_params = Object.keys(values.utm_params)?.length
      ? values.utm_params
      : null;

    try {
      const request = await api.post("/checkout", {
        checkout: {
          ...values,
        },
        visitor_data,
        utm_params,
      });

      if (request.data?.checkout_url) {
        setSubmitting(true);

        global.location.href = `${request.data.checkout_url}?billing_type=${values.billing_type}&reference_id=${values.checkout_id}`;
      } else {
        setSubmitting(false);

        setFieldError("global_error", t("processo-nao-concluido-error"));
      }
    } catch (error) {
      setSubmitting(false);

      if (error.response) {
        if (error.response.status === 422) {
          const data = error.response.data.errors;

          if (data) {
            Object.keys(data).map(errorKey =>
              setFieldError(errorKey, data[errorKey].join(", ")),
            );
          }

          if (error.response.error) {
            setFieldError("global_error", error.response.error);
          }
        } else {
          setFieldError("global_error", defaultErrorMessage);
        }
      } else {
        setFieldError("global_error", defaultErrorMessage);
      }
    }
  },

  displayName: "CheckoutForm",
})(CheckoutFormFields);
