import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiCheckCircle } from "react-icons/bi";
import { useParams } from "react-router";
import { Redirect } from "react-router";

import { Loading, TopBar, Whatsapp } from "../components";

import { usePayment } from "../hooks/usePayment";
import { BILLING_TYPES } from "../components/utils/billing_types";
import { Trans, useTranslation } from "react-i18next";

const colorSuccess = "#009358";

export const PagePaymentSuccess = () => {
  const { id } = useParams();
  const { getPayment, loading, payment, error } = usePayment(id);
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getPayment(id), [id]);

  if (loading || !payment) {
    return <Loading />;
  }

  if (error) {
    return <Redirect to="/" />;
  }

  const { payment: paymentData } = payment;
  const { checkout } = paymentData;
  const status = paymentData.status;

  if (status !== "paid") {
    return <Redirect to={`/payments/${id}`} />;
  }

  return (
    <div>
      <Whatsapp url={checkout?.whatsapp_support_line} />
      <TopBar />

      <Container>
        <Card>
          <Card.Body>
            <Card.Title as="h5">{t("detalhes-do-seu-pagamento")}</Card.Title>
            <Card.Text>
              {t("produto")}: <strong>{checkout?.name}</strong>
              <br />
              {t("voce-escolheu")}:{" "}
              <strong>{BILLING_TYPES[paymentData.billing_type]}</strong>{" "}
              {t("em-x-vezes", {
                installmentCount: paymentData.installment_count,
              })}
              <br />
              {t("numero-do-pedido")}: #{paymentData.invoice_number}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="mt-3 p-3 text-center">
          <Card.Body>
            <h2 className="text-success">
              <BiCheckCircle
                style={{ fill: colorSuccess, marginRight: "5px" }}
              />{" "}
              {t("pagamento-confirmado")}!
            </h2>

            <p className="p-1 my-3 fs-5 w-75 mx-auto">
              <Trans
                i18nKey="mensagem-de-produto-adiquirido"
                components={{ br: <br /> }}
              />
            </p>

            <Button
              variant="success"
              as="a"
              href="https://ibft.com.br/"
              size="lg"
            >
              {t("entrar-na-area-do-aluno")}
            </Button>

            <br />

            <a
              title={t("clique-para-ir-para-tela-de-faturas-pagas")}
              href={`/payments/${id}`}
              className="mt-3 d-block"
            >
              {t("vizualizar-parcelas-pagas")}
            </a>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default PagePaymentSuccess;
