import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { Loading, TopBar } from "../components";

import { usePaymentRecover } from "../hooks/usePaymentRecover";
import { useQuery } from "../hooks/useQuery";
import { translateBillingType } from "../components/utils/billing_types";
import {
  parseStatus,
  parseStatusColor,
} from "../components/utils/payment_statuses";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { Trans, useTranslation } from "react-i18next";

function PagePayments() {
  const query = useQuery();
  const { t } = useTranslation();
  const { getData, loading, data, error } = usePaymentRecover();

  React.useEffect(
    () => getData(query.get("doc_number"), query.get("email")),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (loading) {
    return <Loading />;
  }

  const payments = data?.payments || [];

  return (
    <div>
      <TopBar />

      <Container className="text-center">
        {data?.customer?.name && (
          <>
            <h1>
              {t("ola-customer-x", {
                customerName: data?.customer?.name,
              })}
            </h1>
            <p>{t("parametros-encontrado-para-este-cpf-e-email")}</p>
          </>
        )}
        {!!error && <h1>{t("oops-error")}</h1>}

        <Table striped bordered hover className="mt-5">
          {!error && (
            <thead>
              <tr>
                <th>{t("produto")}</th>
                <th>{t("estado")}</th>
                <th>{t("tipo-de-pagamento")}</th>
                <th>{t("parcelamento")}</th>
                <th>{t("valor")}</th>
                <th>{t("detalhe")}</th>
              </tr>
            </thead>
          )}
          <tbody>
            {error && (
              <tr key="error">
                <td colSpan={6}>
                  <Trans
                    i18nKey="nao-conseguimos-retornar-seus-pagamentos-msg"
                    components={{ strong: <strong /> }}
                  />
                </td>
              </tr>
            )}
            {!error && !payments.length && (
              <tr key="no_payments">
                <td colSpan={6}>{t("sem-agamentos-no-momento")}</td>
              </tr>
            )}
            {!error &&
              !!payments.length &&
              payments.map(({ payment }) => (
                <tr key={payment?.gateway_id}>
                  <td>{payment?.checkout?.name}</td>
                  <td style={{ color: parseStatusColor(payment?.status) }}>
                    {parseStatus(payment?.status)}
                  </td>
                  <td>{translateBillingType(payment?.billing_type)}</td>
                  <td>
                    {t("em-x-vezes", {
                      installmentCount: payment?.installment_count,
                    })}
                  </td>
                  <td>R$ {formatCurrency(parseFloat(payment.total))}</td>
                  <td>
                    <a href={`/payments/${payment.gateway_id}`}>
                      {t("ver-compra")}
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default PagePayments;
