import React from "react";
import Alert from "react-bootstrap/Alert";
import { Trans } from "react-i18next";

export const AllPaidNotice = () => (
  <div>
    <Alert variant="info" className="mt-3">
      <Trans
        i18nKey="nao-ha-parcelas-msg"
        // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
        components={{ a: <a /> }}
      />
    </Alert>
  </div>
);

export default AllPaidNotice;
