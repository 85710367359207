import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { format, parseISO } from "date-fns";
import { formatCurrency } from "@brazilian-utils/brazilian-utils";
import { useGenericAPI } from "../../hooks/useGenericAPI";
import { AllPaidNotice, InlineLoading } from "..";
import { useTranslation } from "react-i18next";

export const BoletoForm = ({ payment }) => {
  const { payment: data } = payment;
  const [showCopy, setShowCopy] = React.useState(false);
  const { t } = useTranslation();
  const installment = React.useMemo(
    () =>
      data.installments_from_gateway.find(i =>
        ["pending", "overdue"].includes(i.status),
      ),
    [data],
  );

  const {
    getRequest,
    loading,
    data: boletoLine,
    error,
  } = useGenericAPI(`boletos/`);

  const allPaid = React.useMemo(
    () =>
      data.installments_from_gateway.filter(i => ["paid"].includes(i.status))
        .length === data.installments_from_gateway.length,
    [data],
  );

  React.useEffect(
    () => {
      if (allPaid) {
        return;
      }

      getRequest(installment.gateway_id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [installment?.gateway_id, allPaid],
  );

  if (allPaid) {
    return <AllPaidNotice />;
  }

  if (loading || !data) {
    return <InlineLoading />;
  }

  const dueDate = format(parseISO(installment.due_date), "dd/MM/yyyy");

  const copyLine = () => {
    if (!boletoLine) {
      return null;
    }

    setShowCopy(true);
  };

  return (
    <div>
      <Card className="mt-3">
        <Card.Body>
          <Card.Title as="h5">
            {t("pagar-x-vencimento-x", {
              description: installment.description,
              dueDate,
            })}
          </Card.Title>
          <p>{t("clique-no-botao-para-acessar-boleto")}</p>

          <Container>
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Button
                  size="lg"
                  title={t("clique-para-baixar-o-boleto")}
                  className="m-3"
                  onClick={() =>
                    global.open(`${installment.boleto_url}?download=true`)
                  }
                >
                  {t("baixar-boleto")}
                </Button>

                <Button
                  size="lg"
                  title={t("clique-para-imprimir-o-boleto")}
                  onClick={() => global.open(installment.boleto_url)}
                >
                  {t("imprimir-boleto")}
                </Button>
              </Col>

              <Col className="d-flex align-items-center justify-content-center flex-column">
                <p className="fs-3 text-success">
                  <strong>
                    {t("valor")}: R$ {formatCurrency(installment.total)}
                  </strong>
                </p>

                <img
                  src="https://s3-sa-east-1.amazonaws.com/asaas-static/images/invoice/site-seguro.png"
                  alt={t("imagem-informando-site-seguro")}
                />

                <p>{t("instrucoes-apos-a-compra")}</p>
              </Col>
            </Row>
          </Container>

          {!error && boletoLine && (
            <div>
              <h5 className="mt-3">
                {t("se-preferir-copie-a-linha-digitavel")}
              </h5>

              <InputGroup className="mb-2">
                <Form.Control
                  readOnly
                  value={boletoLine.identificationField}
                  isValid={showCopy}
                />
                <CopyToClipboard
                  onCopy={copyLine}
                  text={boletoLine.identificationField}
                >
                  <Button
                    title={t("clique-para-copiar-a-linha-do-boleto")}
                    type="button"
                    variant="dark"
                    id="button-addon2"
                  >
                    {showCopy
                      ? t("linha-copiada")
                      : t("copiar-linha-digitavel")}
                  </Button>
                </CopyToClipboard>
              </InputGroup>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

BoletoForm.propTypes = {
  data: PropTypes.object,
};

export default BoletoForm;
