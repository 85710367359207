import { withFormik } from "formik";
import { api } from "../../api";
import {
  isValidCPF,
  isValidCNPJ,
  isValidCEP,
} from "@brazilian-utils/brazilian-utils";
import { CreditCardFormFields } from "..";
import { t } from "i18next";

const defaultErrorMessage = t("request-nao-pode-ser-feito");
const BRASIL = "BR";

export const CreditCardForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ payment, referenceId }) => ({
    gateway_id: payment?.payment?.gateway_id,
    billing_type: "CREDIT_CARD",
    holder_name: payment?.customer?.name || "",
    holder_doc_number: payment?.customer?.doc_number,
    holder_phone_number: payment?.customer?.phone_number,
    address_country:
      payment?.customer?.country.toString().toUpperCase() || BRASIL,
    address_zipcode: "",
    address_number: "",
    address_complement: "",
    credit_card_holder_name: "",
    credit_card_number: "",
    credit_card_expiry_month: "",
    credit_card_expiry_year: "",
    credit_card_ccv: "",
    reference_id: referenceId || "NOT_FOUND",
  }),

  mapPropsToErrors: () => ({}),

  // Custom sync validation
  validate: async values => {
    const errors = {};
    const baseErrorDocNumber = t("documento-invalido");

    if (!values.credit_card_holder_name) {
      errors.credit_card_holder_name = t("nome-do-cartao-invlaido");
    }

    if (!values.credit_card_number) {
      errors.credit_card_number = t("numero-de-cartao-invalido");
    }

    if (!values.holder_name) {
      errors.holder_name = t("nome-completo-de-comprador-vazio");
    }

    if (!values.credit_card_expiry_month) {
      errors.credit_card_expiry_month = t("mes-de-validade-invalido");
    }

    if (!values.credit_card_expiry_year) {
      errors.credit_card_expiry_year = t("ano-de-validade-invalido");
    }

    if (!values.credit_card_ccv) {
      errors.credit_card_ccv = t("codigo-de-seguranca-vazio");
    }

    if (values?.address_country === BRASIL && !values?.address_number) {
      errors.address_number = t("obrigatorio");
    }

    if (values.address_country === BRASIL) {
      if (!values.holder_doc_number) {
        errors.holder_doc_number = baseErrorDocNumber;
      } else if (
        !isValidCPF(values.holder_doc_number) &&
        !isValidCNPJ(values.holder_doc_number)
      ) {
        errors.holder_doc_number = baseErrorDocNumber;
      }
    }

    if (!values.holder_phone_number) {
      if (values.address_country === BRASIL) {
        errors.holder_phone_number = t("preencher-celular-com-ddd");
      } else {
        errors.holder_phone_number = t("preencher-celular-com-codigo-do-pais");
      }
    }

    if (
      values?.address_country === BRASIL &&
      !isValidCEP(values?.address_zipcode)
    ) {
      errors.address_zipcode = t(
        "esta-mensagem-desaparecera-quando-cep-for-valido",
      );
    }

    return errors;
  },

  handleSubmit: async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);

    try {
      const request = await api.post("/payments", { payment: values });
      const data = request?.data;
      const payment = data?.payment;

      if (payment?.status === "paid") {
        setSubmitting(true);
        global.location.href = `/payments/${payment.gateway_id}/success?reference_id=${values.reference_id}&billing_type=CREDIT_CARD`;
      } else {
        setSubmitting(false);
        setFieldError("global_errors", defaultErrorMessage);
      }
    } catch (error) {
      setSubmitting(false);

      if (error.response) {
        if (error.response.status === 422) {
          const data = error.response.data.errors;

          if (data) {
            Object.keys(data).map(errorKey =>
              setFieldError(errorKey, data[errorKey].join(", ")),
            );
          }

          if (error.response.error) {
            setFieldError("global_errors", error.response.error);
          }
        } else {
          setFieldError("global_errors", defaultErrorMessage);
        }
      } else {
        setFieldError("global_errors", defaultErrorMessage);
      }
    }
  },

  displayName: "CreditCardForm",
})(CreditCardFormFields);
