// External
import React from "react";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import { ThemeContext } from "../utils/themeContext";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import { useTranslation } from "react-i18next";

const TopBarAlert = styled(Alert)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 0;

  @media only screen and (max-width: 860px) {
    justify-content: flex-start;
  }
`;

export const TopbarAlertText = styled.p`
  @media only screen and (max-width: 610px) {
    width: 36%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media only screen and (max-width: 450px) {
    justify-content: flex-start;
  }

  margin-bottom: 0;
  margin-left: 10px;
  font-size: 0.8rem;
`;

export const TopBar = () => {
  const theme = React.useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <TopBarAlert variant="primary" theme={theme}>
      <Image src={theme.topBarIcon} width={35} alt={t("checkout-icone")} />
      <TopbarAlertText>
        {process.env.REACT_APP_APP_TOPBAR_TITLE}
      </TopbarAlertText>

      <LanguageSelect />
    </TopBarAlert>
  );
};

TopBar.propTypes = {};
