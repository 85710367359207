import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Text = styled.p`
  font-size: 12px;
  text-align: left;
  margin-bottom: 10px;
`;

export const ForeignAlert = ({ location }) => {
  const t = useTranslation();

  if (location === null || location?.country_code === "BR") {
    return null;
  }

  return (
    <Text className="text-muted">
      <em>
        {t("alerta-para-residentes-fora-do-brasil", {
          countryName: location?.country_name,
        })}
      </em>
    </Text>
  );
};
