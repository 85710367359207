import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ThemeContext } from "../utils/themeContext";
import { BiCheckShield } from "react-icons/bi";
import { Trans, useTranslation } from "react-i18next";

const colorSuccess = "#009358";

export const CheckoutFooter = ({ organization }) => {
  const theme = React.useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div>
      <Container>
        <Row className="justify-content-md-center"></Row>
      </Container>

      <Col className="text-success text-center m-3 fs-6 lh-1">
        <BiCheckShield fontSize={40} color={colorSuccess} />{" "}
        {t("compra-cem-por-cento-segura")}.
      </Col>

      <p className="fs-6 m-0 text-center">
        <Trans
          i18nKey="voce-concorda-com-termos-de-uso-e-politicas-de-privacidade"
          values={{
            termsURL: theme.termsURL,
            privacyPolicyURL: theme.privacyPolicyURL,
          }}
          // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
          components={{ a: <a /> }}
        />
      </p>

      <p className="fs-6">
        {t("instrucoes-depois-do-pagamento")}
        <br />
        {theme.footerText}
      </p>

      <p>
        {organization?.name} © {new Date().getFullYear()} - {t("copyright")}
      </p>
    </div>
  );
};
